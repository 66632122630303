import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import SEO from '../../components/seo'
import Title from '../../components/title'
import Header from '../../components/header'
import Container from '../../components/container'
import FeatureBlock from '../../components/feature-block'
import Layout from '../../layout'
import Image from '../../components/image'
import Brands from '../../components/brands'
import AnimatedArticle from '../../components/animated-article'
import AnimatedAside from '../../components/animated-aside'
import P from '../../components/paragraph'
import BrandBadge from '../../components/brand-badge'

const KoloPage = () => {
  const images = useStaticQuery(graphql`
    query {
      bottle: file(relativePath: { eq: "brands/kolo/kolo-bottle.png" }) {
        childImageSharp {
          fluid(maxWidth: 770) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      group: file(relativePath: { eq: "brands/kolo/kolo-group.png" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO
        title='Kolo'
        description={`A yearning for the classical and the traditional are at Kolos core, whilst simultaneously seeking a clean and modern stylistic approach.   Each fragrance is the culmination of a creative and collaborative process – a blend of both vintage and new sparking recognition but not familiarity.`}
      />
      <Header />

      <Container wide noPadding>
        <FeatureBlock>
          <AnimatedArticle onLoad badge>
            <Title fancy>Kolo</Title>
            <P>
              A yearning for the classical and the traditional are at Kolos
              core, whilst simultaneously seeking a clean and modern stylistic
              approach. Each fragrance is the culmination of a creative and
              collaborative process – a blend of both vintage and new sparking
              recognition but not familiarity.
            </P>
            <BrandBadge brand='kolo' />
          </AnimatedArticle>
          <AnimatedAside>
            <Image
              css={`
                @media (min-width: ${({ theme }) => theme.breakpoint.large}) {
                  margin-top: 100px;
                }
              `}
              fluid={images.bottle.childImageSharp.fluid}
              notch='bottomLeftSmall'
              alt='kolo bottle'
            />
          </AnimatedAside>
        </FeatureBlock>
      </Container>

      {/* <Container width={1100} noPadding overflow css='margin-top: -50px'>
        <FeatureBlock grey={false} center>
          <AnimatedAside right>
            <Image
              fluid={images.group.childImageSharp.fluid}
              shadow={false}
              alt='Perfume bottle'
              css={`
                max-width: 600px;
                @media (max-width: ${({ theme }) => theme.breakpoint.large}) {
                  max-width: 300px;
                  margin: 0 auto;
                }
              `}
            />
          </AnimatedAside>
          <AnimatedArticle>
            <P>
              Kolo harks to the past, whilst learning for the future. This is
              represented with the precise artistry and pencil drawings as well
              as in name. Kolo, being a traditional folk dance, involves all
              members of the community - providing a feeling of togetherness.
              Such inspirations have been captured and form a clean and modern
              brand.
            </P>
          </AnimatedArticle>
        </FeatureBlock>
      </Container> */}

      <Container width={1200}>
        <Title fancy>Other Brands</Title>
        <Brands />
      </Container>
    </Layout>
  )
}

export default KoloPage
